import { DefaultRepository } from './default-repository';

const resource = '/version';
const repository = DefaultRepository.getInstance();

export const VersionRepository = {
  search (query, config = {}) {
    return repository.get(resource, {
      params: query,
      ...config
    });
  },
  findById (id, config = {}) {
    return repository.get(`${resource}/${id}`, config);
  },
  async findCurrent (config = {}) {
    const { data } = await repository.get(resource, {
      params: {
        current: true,
        active: true
      },
      ...config
    })
    return data.numberOfElements > 0
      ? data.content[0]
      : null;
  },
  async findNext (config = {}) {
    const { data } = await repository.get(resource, {
      params: {
        next: true,
        active: true
      },
      ...config
    })
    return data.numberOfElements > 0
      ? data.content[0]
      : null;
  }
};
