<template>
  <v-card
    :disabled="item.locked"
  >
    <VTitleStichprobe
      :id="item.id"
      :text="item.text"
      :zustaendig="item.zustaendig"
      :vorschrift="item.vorschrift"
      :status="item.status"
    />
    <v-card-actions>
      <v-btn
        depressed
        :disabled="item.locked"
        :color="actionColor"
        @click="$router.push({name: 'stichprobeVerify', params: {id: item.id}})"
      >
        Stichprobe &uuml;berpr&uuml;fen
      </v-btn>
      <v-spacer></v-spacer>
      <span
        v-if="item.updated"
        class="ml-2 text-caption"
      >
        Bearbeitet {{ item.updated | timestamp }}
      </span>
    </v-card-actions>
  </v-card>
</template>

<script>
import VTitleStichprobe from '../../../../components/VTitleStichprobe';
import {formatDate} from '../../../../common/lang';

export default {
  components: {
    VTitleStichprobe
  },
  filters: {
    timestamp(date) {
      return formatDate(date, 'DD.MM.YYYY HH:mm');
    }
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  computed: {
    actionColor() {
      let color;
      if (this.item.status === 'NEW') {
        color = 'primary';
      } else {
        color = 'grey lighten-3';
      }
      return color;
    }
  }
};
</script>

