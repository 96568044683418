<template>
  <div>
    <v-container fluid>
      <v-row no-gutters justify="center">
        <v-col cols="12" md="8" xl="6">
          <v-data-iterator
            disable-pagination
            hide-default-footer
            :items="stichproben"
          >
            <template v-slot:header>
              <v-card class="d-flex flex-column pa-2 mb-3">
                <v-select
                  v-model="selectedStatus"
                  class="ma-2"
                  clearable
                  hide-details
                  :items="statusList"
                  item-text="text"
                  item-value="value"
                  label="Status"
                ></v-select>
              </v-card>
            </template>

            <template #no-data>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-text class="text-body-1 text-center">
                      Es liegen keine Stichproben vor.
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <template #default="props">
              <v-row v-for="item in props.items" :key="`v-row-${item.id}`">
                <v-col>
                  <VCardStichprobe
                    :item="item"
                  ></VCardStichprobe>
                </v-col>
              </v-row>
            </template>

            <template #footer>
              <div class="v-data-footer">
                <div class="v-data-footer__select">
                  Elemente pro Seite:
                  <v-select
                    v-model="pagination.size"
                    hide-details
                    :items="pagination.itemsPerPageOptions"
                    @change="pagination.page = 1"
                  />
                </div>
                <div class="v-data-footer__pagination">
                  {{ stichproben.length === 0 ? '–' : 'Seite ' + pagination.page }}
                </div>
                <div class="v-data-footer__icons-before">
                  <v-btn
                    icon
                    :disabled="pagination.first"
                    @click="pagination.page--"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </div>
                <div class="v-data-footer__icons-after">
                  <v-btn
                    icon
                    :disabled="pagination.last"
                    @click="pagination.page++"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VActionRow from '../../components/VActionRow';
import VCardStichprobe from './components/VCardStichprobe';

import {StichprobeRepository} from '../../repositories/stichprobe-repository';
import {VersionRepository} from "../../repositories/version-repository";

export default {
  components: {
    VActionRow,
    VCardStichprobe
  },
  props: {
    user: {}
  },
  data () {
    return {
      stichproben: [],
      selectedStatus: null,
      pagination: {
        itemsPerPageOptions: [5, 10, 15],
        page: 1,
        size: 5,
        first: true,
        last: false
      },
      statusList: [
        {
          value: 'NEW',
          text: 'Neu'
        },
        {
          value: 'OK',
          text: 'In Ordnung'
        },
        {
          value: 'ERROR',
          text: 'Fehlerhaft'
        }
      ],
      versionId: undefined
    };
  },
  computed: {
    query () {
      return {
        status: this.selectedStatus,
        size: this.pagination.size,
        page: this.pagination.page,
        versionId: this.versionId
      }
    }
  },
  watch: {
    selectedStatus () {
      this.pagination.page = 1;
    },
    async query (value) {
      if (value.versionId) {
        const result = await this.searchStichproben({
          ...value,
          page: value.page -1
        });
        this.stichproben = result.content;
        this.pagination.first = result.first;
        this.pagination.last = result.last;
      }
    }
  },
  async mounted () {
    this.selectedStatus = 'NEW';
    const found = await this.searchNextVersion()
    if (found) {
      this.versionId = found.id
    }
  },
  methods: {
    async searchNextVersion () {
      try {
        return await VersionRepository.findNext()
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      }
    },
    async searchStichproben (query) {
      try {
        const { data } = await StichprobeRepository.search(query);
        return data;
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      }
    }
  }
};
</script>

<style scoped>
>>> .v-select .v-input__append-inner .v-icon {
  font-size: 18px !important;
}
</style>
