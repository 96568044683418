<template>
  <v-list-item
    :two-line="!zustaendig"
    :three-line="!!zustaendig"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          :color="icon.color"
          large
          left
          aria-hidden="false"
          :aria-labelledby="`label-status-stichprobe-${id}`"
        >
          {{ icon.icon }}
        </v-icon>
      </template>
      <span :id="`label-status-stichprobe-${id}`">Status: {{ icon.text }}</span>
    </v-tooltip>
    <v-list-item-content>
      <div class="text-caption grey--text text--darken-2 mb-0">{{ zustaendig }}</div>
      <v-list-item-title class="text-h5 mb-1">{{ text }}</v-list-item-title>
      <v-list-item-subtitle>{{ vorschrift }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
      default: 0
    },
    text: {
      type: String,
      required: true,
      default: ''
    },
    zustaendig: {
      type: String,
      required: false,
      default: ''
    },
    vorschrift: {
      type: String,
      required: true,
      default: ''
    },
    status: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    icon () {
      let icon;
      let color;
      let text;

      if (this.status === 'NEW') {
        icon = 'mdi-alert-circle-outline';
        color = 'grey darken-2';
        text = 'Neu';
      } else {
        icon = this.status === 'OK' ? 'mdi-check' : 'mdi-close';
        color = this.status === 'OK' ? 'green darken-2' : 'red accent-4';
        text = this.status === 'OK' ? 'In Ordnung' : 'Fehlerhaft';
      }
      return {
        icon,
        color,
        text
      };
    }
  }
};
</script>
